import React, { useEffect, useState, useContext } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

import { FormContext } from '../Form/context';
import { CheckoutContext } from './context';

import StoreData from '../../data/store';

export default function PaypalButton({ total, currency }) {
  const [token, setToken] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const { values } = useContext(FormContext);
  const { checkoutError, createOrder, validateCheckout, processOrder } =
    useContext(CheckoutContext);

  useEffect(() => {
    setDisabled(validateCheckout());
  }, [values]);

  useEffect(() => {
    StoreData.paypalToken()
      .then(res => setToken(res.token))
      .catch(err => checkoutError(err.message));
  }, []);

  return (
    <div style={{ maxWidth: `300px` }}>
      <PayPalScriptProvider
        options={{
          'client-id': process.env.GATSBY_PAYPAL_KEY,
          'data-client-token': token,
          currency,
          intent: 'capture',
        }}
      >
        <PayPalButtons
          style={{ layout: 'horizontal', tagline: false }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: total,
                  },
                },
              ],
              application_context: {
                shipping_preference: 'NO_SHIPPING',
              },
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then(details => {
              processOrder();
              createOrder(details.id);
            });
          }}
          onError={err => checkoutError(err)}
          disabled={disabled}
        />
      </PayPalScriptProvider>
    </div>
  );
}
