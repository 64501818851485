import React, { useEffect, useState, useContext } from 'react';
import {
  PayPalScriptProvider,
  PayPalHostedFieldsProvider,
  PayPalHostedField,
  usePayPalHostedFields,
} from '@paypal/react-paypal-js';
import getSymbolFromCurrency from 'currency-symbol-map';

import { FormContext } from '../Form/context';
import { CheckoutContext } from './context';

import StoreData from '../../data/store';
import Placeholder from '../Placeholder';

import { BorderRadius, MainColour } from '../../utils/variables';

import loadComponent from '../Loadable';

const Button = loadComponent('button-input');

const declineError = `Payment have been declined by your issuing bank, please refer to them to resolve this issue or use an alternative payment method.`;
const threeDSecureError = `Unable to authorize payment with your bank, please refer to them to resolve this issue or use an alternative payment method.`;

function SubmitPayment({ total, currency }) {
  const [disabled, setDisabled] = useState(true);

  const hostedFields = usePayPalHostedFields();

  const { values } = useContext(FormContext);
  const {
    checkout,
    validateCheckout,
    processOrder,
    createOrder,
    checkoutError,
  } = useContext(CheckoutContext);

  useEffect(() => {
    setDisabled(validateCheckout());
  }, [values]);

  const handleErrors = res => {
    const allErrors = [];
    res?.details?.forEach(item => allErrors.push(item.description));
    const errorMessage = `Unable to process payment: ${
      allErrors.length > 0 ? allErrors.join(', ') : declineError
    }`;
    checkoutError(errorMessage);
  };

  const submitHandler = e => {
    e.preventDefault();
    processOrder();

    hostedFields.cardFields
      .submit({
        cardholderName: `${values.first_name} ${values.last_name}`,
        billingAddress: {
          streetAddress: values.street_address,
          region: values.county,
          locality: values.city,
          postalCode: values.post_code,
          countryCodeAlpha2: values.country,
        },
        contingencies: ['3D_SECURE'],
      })
      .then(order => {
        if (
          order.liabilityShift === 'POSSIBLE' ||
          order.liabilityShift === 'NO'
        ) {
          // 3D Secure passed successfully
          StoreData.paypalCapture(order.orderId)
            .then(res => {
              if (res?.data?.status && res?.data?.status === 'COMPLETED') {
                if (
                  res?.data?.purchase_units[0]?.payments?.captures[0]
                    ?.status === 'COMPLETED'
                ) {
                  const transactionId =
                    res?.data?.purchase_units[0]?.payments?.captures[0]?.id;

                  createOrder(transactionId);
                } else {
                  checkoutError(declineError);
                }
              } else {
                handleErrors(res);
              }
            })
            .catch(err => checkoutError(err.message));
        } else {
          checkoutError(threeDSecureError);
        }

        StoreData.logRequest(`paypal3dSecure: ${JSON.stringify(order)}`).catch(
          () => console.log(`Error storing log in firebase`),
        );
      })
      .catch(err => {
        handleErrors(err);
      });
  };

  return (
    <Button
      onClick={e => submitHandler(e)}
      value={`Pay ${getSymbolFromCurrency(currency)}${total}`}
      disabled={disabled}
      processing={checkout.processing}
      green
    />
  );
}

export default function PaypalHosted({ total, currency }) {
  const [token, setToken] = useState(null);

  const { checkoutError } = useContext(CheckoutContext);

  useEffect(() => {
    StoreData.paypalToken()
      .then(res => setToken(res.token))
      .catch(err => checkoutError(err.message));
  }, []);

  const buttonProps = {
    total,
    currency,
  };

  return (
    <div className="paypalHosted">
      {token !== null ? (
        <PayPalScriptProvider
          options={{
            'client-id': process.env.GATSBY_PAYPAL_KEY,
            'data-client-token': token,
            components: 'hosted-fields',
          }}
        >
          <PayPalHostedFieldsProvider
            createOrder={() => {
              return StoreData.paypalCreate(total, currency)
                .then(res => res.id)
                .catch(err => console.log(err));
            }}
          >
            <strong>Card Number</strong>
            <PayPalHostedField
              id="card-number"
              hostedFieldType="number"
              options={{
                selector: '#card-number',
                placeholder: '4111 1111 1111 1111',
              }}
            />
            <strong>Card Expiry Date</strong>
            <PayPalHostedField
              id="expiration-date"
              hostedFieldType="expirationDate"
              options={{
                selector: '#expiration-date',
                placeholder: 'MM/YY',
              }}
            />
            <strong>Card CVV (3 digit number on signature strip)</strong>
            <PayPalHostedField
              id="cvv"
              hostedFieldType="cvv"
              options={{ selector: '#cvv', placeholder: '123' }}
            />

            <SubmitPayment {...buttonProps} />
          </PayPalHostedFieldsProvider>
        </PayPalScriptProvider>
      ) : (
        <div>
          <Placeholder
            height="48px"
            colour="#222"
            style={{ flex: 1, borderRadius: BorderRadius }}
          />
          <Placeholder
            width="200px"
            height="48px"
            colour={MainColour}
            style={{ marginLeft: `30px`, borderRadius: BorderRadius }}
          />
        </div>
      )}
    </div>
  );
}
