import React from 'react';

import PaypalHosted from './paypal-hosted';
import PaypalButton from './paypal';

import cardLogos from '../../images/card-icons.webp';

import { Bold, BorderRadius } from '../../utils/variables';
import './payments.css';

import loadComponents from '../Loadable';
import { FormContext } from '../Form/context';

const H2 = loadComponents('h2');
const ButtonHidden = loadComponents('button-hidden');
const ButtonInput = loadComponents('button-input');
const Alert = loadComponents('alert');
// const ButtonSubmit = loadComponents('button-submit');

export default class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      processing: false,
    };
    // this.cardPayment = this.cardPayment.bind(this);
  }

  tabRadius = (i, count) => {
    if (i === 0) {
      return `${BorderRadius} 0 0 0`;
    }

    if (i === count - 1) {
      return `0 ${BorderRadius} 0 0`;
    }

    return ``;
  };

  /* cardPayment = () => {
    const { total, currency } = this.props;
    const { processing } = this.state;

    const payButton = total && `Pay ${getSymbolFromCurrency(currency)}${total}`;
    return (
      <div
        style={{
          display: `flex`,
          justifyContent: `space-between`,
          flexDirection: `column`,
        }}
      >
        <div className="checkoutField">
          <strong>Card Number</strong>
          <CardNumberElement />
        </div>
        <div className="checkoutField">
          <strong>Card Expiry Date</strong>
          <CardExpiryElement />
        </div>
        <div className="checkoutField">
          <strong>Card CVV (3 digit number on signature strip)</strong>
          <CardCvcElement />
        </div>
        {payButton && (
          <div style={{ flex: `0 0 25%` }}>
            <ButtonSubmit
              label={payButton}
              processing={processing}
              className="form-btn"
              style={{
                width: `100%`,
                marginTop: `10px`,
              }}
              green
            >
              {payButton}
            </ButtonSubmit>
          </div>
        )}
      </div>
    );
  }; */

  async emailLink(e) {
    e.preventDefault();

    this.setState({ processing: true });

    const { items } = this.props;
    const { values } = this.context;

    const submissionUrl = `${process.env.GATSBY_FUNCTIONS_URL}contact/save`;

    const user = await fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .catch(() =>
        alert(
          `Unable to process request. Please disable any adblockers or VPN's to proceed.`,
        ),
      );

    const body = JSON.stringify({
      email: values.email_address,
      url: window.location.href,
      name: items[0].name,
      type: `payment`,
      user,
    });

    fetch(submissionUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    })
      .then(response => response.json())
      .then(response => {
        // console.log(response);
        if (response.contact) {
          this.setState({ disable: true });
        }
      })
      .catch(err => alert(err))
      .finally(() => this.setState({ processing: false }));
  }

  render() {
    const { active, togglePayment, total, currency, orderId } = this.props;
    const { values } = this.context;
    const { disable, processing } = this.state;

    const payments = [
      {
        title: 'Credit/Debit Card',
        // gateway: this.cardPayment,
        gateway: () => <PaypalHosted total={total} currency={currency} />,
        icons: true,
      },
      {
        title: 'Paypal',
        gateway: () => <PaypalButton total={total} currency={currency} />,
      },
    ];
    return (
      <>
        <H2>Payment Method</H2>
        <Alert info style={{ marginBottom: `30px` }}>
          Once payment is processed all confirmation documentation will be sent
          to you via the email address provided with this application. If you
          require an appointment, this can be made once your application is
          verified. We will confirm this within 48 Hours.
        </Alert>
        <ul style={tabStyle}>
          {payments.map((item, i) => (
            <li
              key={item.title}
              className={`tab${active === i ? ` active` : ``}`}
              style={{
                ...tabLi,
                backgroundColor: active === i ? `#fff` : `#f7f7f7`,
                borderWidth: i === 0 ? `1px 1px 0 1px` : `1px 1px 0 0`,
                borderRadius: this.tabRadius(i, payments.length),
              }}
            >
              <ButtonHidden
                onClick={e => togglePayment(e, i)}
                style={tabButton}
              >
                {item.icons ? (
                  <img
                    src={cardLogos}
                    alt={item.title}
                    style={{ maxHeight: `36px`, maxWidth: `100%` }}
                  />
                ) : (
                  item.title
                )}
              </ButtonHidden>
            </li>
          ))}
        </ul>
        <div style={paymentStyle}>
          {payments[active].gateway()}
          <div className="payLater" style={payLater}>
            <div className="description">
              <strong>If you need more time</strong>
              <p style={{ fontSize: `14px` }}>
                You can request a link to make this payment later.
                <br />
                We will email you with a link the reference for this application
                is: <strong>#{orderId}</strong>
              </p>
            </div>
            <ButtonInput
              value={!disable ? 'Email payment link' : 'Sent!'}
              onClick={e => this.emailLink(e)}
              className="button"
              disabled={values.email_address === undefined || disable}
              processing={processing}
              orange
            />
          </div>
        </div>
      </>
    );
  }
}

Payments.contextType = FormContext;

const tabStyle = {
  display: `inline-flex`,
  margin: 0,
};

const tabLi = {
  borderStyle: `solid`,
  borderColor: `rgba(0,0,0,0.2)`,
  position: `relative`,
  cursor: `pointer`,
  fontSize: `0.875rem`,
  fontWeight: Bold,
};

const tabButton = {
  padding: `1rem 1.875rem`,
  textTransform: `uppercase`,
  height: `100%`,
};

const paymentStyle = {
  border: `1px solid rgba(0,0,0,.2)`,
  borderRadius: `0 ${BorderRadius} ${BorderRadius} ${BorderRadius}`,
  padding: `1.875rem`,
};

const payLater = {
  marginTop: `30px`,
  paddingTop: `30px`,
  borderTop: `1px solid rgba(0,0,0,0.1)`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `space-between`,
  flexWrap: `wrap`,
};
